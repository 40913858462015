import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {depositsActions, depositsSelectors} from "../../Modules/deposits";
import {useActions} from "../common/hooks/use-actions";
import styled from 'styled-components'
import {SearchForm} from "./SearchForm";
import {sessionSelectors} from "../../Modules/session";
import {DepositTable} from "./DepositTable";

const SearchBlock = styled.div`
    background-color: #eee;
    width: 1200px;
    margin: 50px auto;
    padding: 30px;
    border-radius: 5px;
`

export const DepositList = () => {
    const {load} = useActions(depositsActions)
    const deposits = useSelector((depositsSelectors.items))
    const lcode = useSelector(sessionSelectors.lcode)

    useEffect(() => {
        load({lcode})
    }, [lcode]);


    return <SearchBlock>
        <SearchForm/>
        <DepositTable items={deposits}/>
    </SearchBlock>

}
