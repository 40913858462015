import React, { useState, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { paymentTypesNoBank } from '../../../../../Modules/helpers/paymentTypes'
import { Select, InputNumber } from '../../../../form-stuff'
import { ButtonGroup, Button } from '../../../../buttons'
import {
  defaultPaymentTypeOption,
  defaultQuantityOption,
  defaultDateOption,
} from '../../../constants/default-values'
import { BreakfastSelect } from './BreakfastSelect'
import { day } from '../../../../../Modules/formatters/date'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../common/hooks/use-actions'
import * as servicesActions from '../../../../../Modules/reservation/actions/services'
import { reservationSelectors } from '../../../../../Modules/reservation'
import { currentDateSelectors } from '../../../../../Modules/current-date'
import styled from 'styled-components'
import moment from 'moment'
import { settingsSelectors } from '../../../../../Modules/settings'

const NoticeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`

const Notice = styled.p`
  color: #da4e4e;
  font-size: 12px;
  margin: 0;
  text-align: center;
`

const noDatesOption = {
  label: 'Нет доступных дат',
  value: '',
}

export const Footer = ({breakfastService}) => {
  const [deliveryDate, setDeliveryDate] = useState('')
  const [breakfastPk, setBreakfastPk] = useState(null)
  const [quantity, setQuantity] = useState('')
  const [paymentType, setPaymentType] = useState('')

  const resetValues = () => {
    setDeliveryDate('')
    setBreakfastPk(null)
    setQuantity('')
    setPaymentType('')
  }

  const [errors, setErrors] = useState([])
  const resetErrors = () => setErrors([])

  const reservationStart = useSelector(reservationSelectors.reservationStart)
  const currentDate = useSelector(currentDateSelectors.today)
  const breakfastLimitTime = useSelector(settingsSelectors.breakfastLimitTimeFormatted)

  const { createService } = useActions(servicesActions)

  const handleSubmit = () => {
    resetErrors()

    const currentErrors = []

    if (!deliveryDate) currentErrors.push('deliveryDate')
    if (!breakfastPk) currentErrors.push('breakfastPk')
    if (!quantity) currentErrors.push('quantity')
    if (!paymentType) currentErrors.push('paymentType')

    if (currentErrors.length > 0) {
      setErrors(currentErrors)
      return
    }

    const date = moment(currentDate).isSameOrAfter(reservationStart)
      ? currentDate
      : reservationStart

    createService({
      serviceType: 'breakfast',
      name: 'Завтрак',
      price: breakfastService.price,
      quantity,
      paymentType,
      date,
      special_service_id: breakfastService.pk,
      breakfast_pk: breakfastPk,
      breakfast_delivery_date: deliveryDate,
      nds_value: breakfastService.nds_value
    })

    resetValues()
  }

  const availableDates = useSelector(
    reservationSelectors.availableBreakfastDates
  )

  const dateSelectOptions = useMemo(() => {
    return availableDates.map(date => ({
      label: day(date),
      value: date,
    }))
  }, [availableDates])

  return (
    <React.Fragment>
      <Row style={{ marginBottom: 16 }}>
        <Col xs={3}>
          <Select
            label="Дата доставки"
            defaultOption={
              dateSelectOptions.length > 0 ? defaultDateOption : noDatesOption
            }
            disabled={dateSelectOptions.length === 0}
            options={dateSelectOptions}
            value={deliveryDate}
            onChange={setDeliveryDate}
            hasError={errors.includes('deliveryDate')}
          />
        </Col>
        <Col xs={3}>
          <BreakfastSelect
            breakfastPk={breakfastPk}
            onChange={setBreakfastPk}
            hasError={errors.includes('breakfastPk')}
          />
        </Col>
        <Col xs={3}>
          <InputNumber
            label="Количество"
            defaultValue={defaultQuantityOption}
            value={quantity}
            onChange={setQuantity}
            hasError={errors.includes('quantity')}
          />
        </Col>
        <Col xs={3}>
          <Select
            label="Метод оплаты"
            defaultOption={defaultPaymentTypeOption}
            options={paymentTypesNoBank}
            value={paymentType}
            onChange={setPaymentType}
            hasError={errors.includes('paymentType')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <NoticeContainer>
            <Notice>Заказ на завтра можно делать до {breakfastLimitTime}</Notice>
          </NoticeContainer>
        </Col>
        <Col xs={3} xsOffset={6}>
          <ButtonGroup>
            <Button
              type="success"
              onClick={handleSubmit}
              style={{ width: '100%' }}
            >
              Добавить завтрак
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </React.Fragment>
  )
}
