import * as types from './types'
import * as constants from './constants'
import ls from 'local-storage'

function getInitialState() {
    const sizeLsValue = ls.get(constants.SIZE_LS_KEY)
    const size = sizeLsValue ? Number(sizeLsValue) : 100

    return {
        reservations: [],
        notes: [],
        groupReservations: [],
        roomOccupancy: null,
        size,
        isLoading: false,
        isLoaded: false,
        isFailed: false,
        error: '',
    }
}

export const reducer = (state = getInitialState(), action) => {
    const {payload} = action

    switch (action.type) {
        case types.LOAD_DATA_REQUEST:
            return {
                ...getInitialState(),
                isLoading: true,
            }

        case types.LOAD_DATA_SUCCESS:
            return {
                ...state,
                reservations: payload.reservations || [],
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                error: payload.error,
            }

        case types.LOAD_NOTES_SUCCESS:
            return {
                ...state,
                notes: payload.items,
            }

        case types.LOAD_GROUP_RESERVATIONS_SUCCESS:
            return {
                ...state,
                groupReservations: payload.items,
            }

        case types.UPDATE_RESERVATION:
            return {
                ...state,
                reservations: state.reservations.map(reservation => {
                    if (reservation.pk === payload.reservation.pk) {
                        return payload.reservation
                    }

                    return reservation
                }),
                groupReservations: state.groupReservations.map(reservation => {
                    if (reservation.pk === payload.reservation.pk) {
                        return payload.reservation
                    }

                    return reservation
                }),
            }

        case types.LOAD_ROOM_OCCUPANCY:
            return {
                ...state,
                roomOccupancy: payload.roomOccupancy
            }

        case types.SET_SIZE:
            return {
                ...state,
                size: payload,
            }

        case types.RESET:
            return getInitialState()

        default:
            return state
    }
}
