import React, {useEffect, useRef} from 'react'
import Modal from 'react-modal'
import {ModalContent, ModalControls, ModalHR, ModalTitle,} from '../../common/ModalParts'
import {renderText} from '../helpers/render-text'
import {renderButtons} from '../helpers/render-buttons'
import {getStyle} from '../helpers/get-style'
import {LogsAPI} from '../../../Modules/logs-api'
import {getModalOpenLogText} from '../helpers/get-modal-open-log-text'
import {useCloseOnClickOutside} from '../helpers/use-close-on-click-outside'
import {ModalHeader} from "react-bootstrap";

export const modalActions = {
    close: 'close',
}

const defaultButtons = [
    {
        text: 'Ок',
        color: 'success',
        action: modalActions.close,
        position: 'right',
    },
]

export function alert({
                          title = 'Внимание',
                          text,
                          buttons = defaultButtons,
                          width = 640,
                          maxContentHeight = 9999,
                          disableCloseOnClickOutside = false,
                          headerCloseButton = false
                      }) {
    return ({isOpen, resolve}) => {
        const ref = useRef()

        const setRef = value => {
            ref.current = value
        }

        useEffect(() => {
            const logText = getModalOpenLogText({title, text})
            LogsAPI.event(logText)
        }, [])

        useCloseOnClickOutside({
            ref,
            disableCloseOnClickOutside,
            onClose: () => resolve(modalActions.close),
        })

        return (
            <Modal
                isOpen={isOpen}
                contentRef={setRef}
                style={getStyle({width, zIndex: 1000})}
            >
                {headerCloseButton
                    ? <ModalHeader closeButton onHide={() => resolve(modalActions.close)}>
                        <ModalTitle padding={1}>{title}</ModalTitle>
                    </ModalHeader>
                    : <ModalTitle>{title}</ModalTitle>}
                <ModalHR/>
                <ModalContent maxHeight={maxContentHeight}>
                    {renderText(text)}
                </ModalContent>
                <ModalHR/>
                <ModalControls>
                    {renderButtons({
                        buttons,
                        defaultButtons,
                        resolve,
                        beforeClick: ({text}) => LogsAPI.pressAction(text),
                    })}
                </ModalControls>
            </Modal>
        )
    }
}

alert.actions = modalActions
alert.defaultButtons = defaultButtons
