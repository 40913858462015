import axios from 'axios'

import endpoint from './api'

import shiftCodes from './shiftCodes'
import checkCodes from './checkCodes'

import generateGUID from './generateGUID'
import getRandom5DigitNumber from './getRandom5DigitNumber'
import store from '../../store'
import paymentTypes from '../helpers/paymentTypes'
import {saveCheckCall} from '../api/ccAPI/requests'

const saveCheck = async ({
                             linesStringCash,
                             linesStringCard,
                             values,
                             transactionValues,
                             printCashierInfo: {Info: checkInfo} = {}
                         }) => {
    const {lcode, date, isRefund, bookingNumber, guestName} = transactionValues

    const checkBase = {
        lcode,
        date,
        isRefund,
        bookingNumber,
        guestName,
        room: '-',
    }

    const promises = []

    if (values.payedByCash || values.refundedByCash) {
        let payedCashAmount = 0
        linesStringCash.split('@').forEach(position => {
            const values = position.split('~')
            payedCashAmount += +values[2]
        })

        promises.push(
            saveCheckCall({
                ...checkBase,
                total: payedCashAmount,
                paymentType: paymentTypes.cash,
                positions: linesStringCash,
                kkt: checkInfo?.KktNumber
            })
        )
    }

    if (values.payedByCard || values.refundedByCard) {
        promises.push(
            saveCheckCall({
                ...checkBase,
                total: values.cardSumm, // Card payments do not have short change
                paymentType: paymentTypes.card,
                positions: linesStringCard,
                kkt: checkInfo?.KktNumber
            })
        )
    }

    return await Promise.all(promises)
}

class CheckPrintController {
    userData = {
        user: 'Admin',
        password: '',
    }

    deviceData = {
        NumDevice: 0,
        IdDevice: '',
    }

    defaultCheckData = {
        NotPrint: false,
        NumberCopies: 0,
        TaxVariant: '',
        RRNCode: '',
        AuthorizationCode: '',
    }

    sellCheckData = {
        IsFiscalCheck: true,
        TypeCheck: checkCodes.sell,
    }

    refundCheckData = {
        IsFiscalCheck: true,
        TypeCheck: checkCodes.refund,
        RRNCode: '',
        AuthorizationCode: '',
    }

    getAdminName = () => {
        const state = store.getState()

        const {settings} = state

        return settings.cashier
    }

    executeCommand = data => {
        const {user, password} = this.userData

        return axios({
            url: endpoint + 'Execute',
            method: 'post',
            data,
            headers: {
                Authorization: 'Basic ' + window.btoa(`${user}:${password}`),
            },
        }).then(res => res.data)
    }

    getKKTState = () =>
        this.executeCommand({
            Command: 'GetDataKKT',
            ...this.deviceData,
            IdCommand: generateGUID(),
        })

    getXReport = () =>
        this.executeCommand({
            Command: 'XReport',
            ...this.deviceData,
            IdCommand: generateGUID(),
        })

    getDailyCardsTotals = () =>
        this.executeCommand({
            Command: 'Settlement',
            ...this.deviceData,
            IdCommand: generateGUID(),
        })

    getTerminalReport = () =>
        this.executeCommand({
            Command: 'TerminalReport',
            Detailed: true,
            ...this.deviceData,
            IdCommand: generateGUID(),
        })

    getDataCheck = async () =>
        this.executeCommand({
            Command: 'GetDataCheck',
            ...this.deviceData,
            IdCommand: generateGUID(),
        })

    printTerminalReport = async () => {
        const data = await this.getTerminalReport()

        return this.printSlip(
            data.Slip.split('\r\n').map(s => ({
                PrintText: {Text: s},
            }))
        )
    }

    printCheckAgain = async () => {
        const data = await this.getDataCheck()

        if (data.Error) return data

        return this.printSlip(
            data.Slip.split('\r\n').map(s => ({
                PrintText: {Text: s},
            }))
        )
    }

    checkStatus = async () => {
        const state = await this.getKKTState()
        const status = state.Status
        const error = state.Error

        return {
            isStatusOK: status === 0,
            error,
        }
    }

    openShift = () =>
        this.executeCommand({
            Command: 'OpenShift',
            ...this.deviceData,
            CashierName: this.getAdminName(),
            IdCommand: generateGUID(),
        })

    closeShift = () =>
        this.executeCommand({
            Command: 'CloseShift',
            ...this.deviceData,
            CashierName: this.getAdminName(),
            IdCommand: generateGUID(),
        })

    doActionWithCash = async data => {
        const statusInfo = await this.checkStatus()
        const {isStatusOK, error} = statusInfo

        if (!isStatusOK)
            return {
                Error: error,
            }

    await this.refreshShift()

        return this.executeCommand(data)
    }

    depositCash = amount =>
        this.doActionWithCash({
            Command: 'DepositingCash',
            ...this.deviceData,
            Amount: amount,
            IdCommand: generateGUID(),
        })

    paymentCash = amount =>
        this.doActionWithCash({
            Command: 'PaymentCash',
            ...this.deviceData,
            Amount: amount,
            IdCommand: generateGUID(),
        })

    refreshShift = async () => {
        const state = await this.getKKTState()
        const shiftCode = state.Info.SessionState

        if (shiftCode === shiftCodes.closed) {
            return this.openShift()
        }

        if (shiftCode === shiftCodes.expired) {
            await this.closeShift()
            return this.openShift()
        }

        return state
    }

    payByPaymentCard = ({amount}) =>
        this.executeCommand({
            Command: 'PayByPaymentCard',

            ...this.deviceData,

            Amount: amount,
            IdCommand: generateGUID(),
        })

    lockPaymentCardSumm = ({amount}) =>
        this.executeCommand({
            Command: 'AuthorisationByPaymentCard',

            ...this.deviceData,

            Amount: amount,
            IdCommand: generateGUID(),
        })

    writeOffPaymentCardSumm = ({amount, rrn, authCode}) =>
        this.executeCommand({
            Command: 'AuthConfirmationByPaymentCard',

            ...this.deviceData,

            Amount: amount,
            RRNCode: rrn,
            AuthorizationCode: authCode,
            IdCommand: generateGUID,
        })

    unlockPaymentCardSumm = ({amount, rrn, authCode}) =>
        this.executeCommand({
            Command: 'CancelAuthorisationByPaymentCard',

            ...this.deviceData,

            Amount: amount,
            RRNCode: rrn,
            AuthorizationCode: authCode,
            IdCommand: generateGUID,
        })

    returnPaymentByPaymentCard = ({amount, rrn, authCode}) =>
        this.executeCommand({
            Command: 'ReturnPaymentByPaymentCard',

            ...this.deviceData,

            Amount: amount,
            RRNCode: rrn,
            AuthorizationCode: authCode,
            IdCommand: generateGUID,
        })

    printSlip = strings =>
        this.executeCommand({
            Command: 'RegisterCheck',

            ...this.deviceData,

            IsFiscalCheck: false,
            IdCommand: generateGUID(),

            CheckStrings: strings,
        })

    printCheckWithoutProcessing = async ({
                                             lines,
                                             linesStringCash,
                                             linesStringCard,
                                             values,
                                             additionalProps,
                                         }) => {
        const printCashierInfo = await this.refreshShift()

        const printResponse = await this.executeCommand({
            Command: 'RegisterCheck',

            ...this.deviceData,
            ...this.sellCheckData,

            CashierName: this.getAdminName(),
            CashierVATIN: store.getState().settings.inn || 'not found',

            IsFiscalCheck: true,
            PayByProcessing: false,

            IdCommand: generateGUID(),

            CheckStrings: lines,

            ElectronicPayment: values.cardSumm,
            ReceiptNumber: getRandom5DigitNumber(),
        })

        if (printResponse.Status === 0) {
            try {
                await saveCheck({
                    linesStringCash,
                    linesStringCard,
                    values,
                    additionalProps,
                    printCashierInfo
                })
            } catch {
                // skip
            }
        }

        return printResponse
    }
}

export default new CheckPrintController()
