import * as types from './types'

const initialState = {
    items: [],
    isLoading: false,
    isLoaded: false,
    isFailed: false,
}

export const reducer = (state = initialState, action) => {
    const {payload} = action

    switch (action.type) {
        case types.LOAD_REQUEST:
            return {
                ...initialState,
                isLoading: true,
            }

        case types.LOAD_SUCCESS:
            return {
                ...state,
                items: payload.items,
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }

        case types.UPDATE_REQUEST:
            const items = state?.items.reduce((a, b) => {
                if (b.id === payload.item.id) {
                    a.push(payload.item)
                } else a.push(b)
                return a
            }, [])
            return {
                ...state,
                items,
                isLoading: false,
                isFailed: false,
            }

        case types.RESET:
            return initialState

        default:
            return state
    }
}
