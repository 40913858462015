import React from 'react'

import Body from '../../shared/table-common/Body'

import TableBlock from '../../shared/TableBlock'
import TableTitle from '../../shared/TableTitle'
import TableStatus from '../../shared/TableStatus'

import {TableHeadRow} from '../../shared/TableHeadRow'
import {TableDataRow} from '../../shared/TableDataRow'
import {getTimeString, getTotalString} from '../../shared/formatters'

export const RefundsTable = ({items, isFailed}) => {
    const getMainContent = () => {

        if (isFailed) return <TableStatus color="pageText">Ошибка</TableStatus>

        if (!items.length)
            return (
                <TableStatus color="pageText">
                    Отмены с такими параметрами не найдены
                </TableStatus>
            )

        const rows = items
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map(item => (
                <TableDataRow
                    key={item.id}
                    rows={[
                        {content: getTimeString(item.date)},
                        {
                            content: getTotalString.forRefund(item.total),
                            textAlign: 'right',
                        },
                    ]}
                />
            ))

        return (
            <React.Fragment>
                <TableHeadRow
                    rows={[
                        {content: 'Время отмены'},
                        {content: 'Сумма', textAlign: 'right'},
                    ]}
                />
                <Body>{rows}</Body>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <TableTitle>Отмены</TableTitle>
            <TableBlock>{getMainContent()}</TableBlock>
        </React.Fragment>
    )
}
