import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {paymentTypesList, paymentTypesNoBank} from '../../../../../Modules/helpers/paymentTypes'
import {Input, InputNumber, Select} from '../../../../form-stuff'
import {Button, ButtonGroup} from '../../../../buttons'
import {defaultPaymentTypeOption, defaultQuantityOption,} from '../../../constants/default-values'
import {useSelector} from 'react-redux'
import {useActions} from '../../../../common/hooks/use-actions'
import * as servicesActions from '../../../../../Modules/reservation/actions/services'
import {reservationSelectors} from '../../../../../Modules/reservation'
import {useDateSelectData} from '../../../common/hooks/use-date-select-data'
import {specialServiceTypesSelectors} from '../../../../../Modules/special-service-types'
import styled from 'styled-components'
import {CarNumberSelector} from "../CarNumberSelector";

const Notice = styled.p`
    color: #da4e4e;
    font-size: 14px;
    margin: 0;
    text-align: center;
`


export const Footer = ({carLimit}) => {
    const availableDates = useSelector(reservationSelectors.availableDates)
    const parkingServices = useSelector(specialServiceTypesSelectors.parkingService)
    const defaultPrice = parkingServices ? parkingServices.price : 0

    const [paymentTypeList, setPaymentTypeList] = useState(paymentTypesList)

    const {
        date,
        setDate,
        resetDate,
        dateOptions,
        dateDefaultOption,
    } = useDateSelectData(availableDates, true)

    useEffect(() => {
        const list = parkingServices ? parkingServices.is_available_for_sale_by_bank
                ? paymentTypesList
                : paymentTypesNoBank
            : paymentTypesList
        setPaymentTypeList(list)
        setPaymentType('')
    }, [parkingServices])

    const [price, setPrice] = useState(defaultPrice)
    const [quantity, setQuantity] = useState('')
    const [paymentType, setPaymentType] = useState('')
    const [carNumbers, setCarNumbers] = useState([])

    useEffect(() => {
        setPrice(defaultPrice * (carNumbers?.length || 1))
    }, [carNumbers]);

    const resetValues = () => {
        resetDate()
        setPrice(defaultPrice)
        setQuantity('')
        setPaymentType('')
        setCarNumbers([])
    }

    const [errors, setErrors] = useState([])
    const resetErrors = () => setErrors([])

    const {createService} = useActions(servicesActions)

    const handleSubmit = () => {
        resetErrors()
        const currentErrors = []

        if (!date) currentErrors.push('date')
        if (!price) currentErrors.push('price')
        if (!quantity) currentErrors.push('quantity')
        if (!paymentType) currentErrors.push('paymentType')
        if (!carNumbers || carNumbers.length === 0) currentErrors.push('parking_car_numbers')

        if (currentErrors.length > 0) {
            setErrors(currentErrors)
            return
        }

        const parking_car_numbers = carNumbers.map(({value}) => value)
        const data = {
            ...parkingServices,
            serviceType: 'parking',
            price,
            quantity,
            paymentType,
            date,
            special_service_id: parkingServices.pk,
            parking_car_numbers
        }
        if (date === "all") {
            dateOptions.forEach(({value: date}) => {
                createService({...data, date})
            })
        } else
            createService(data)

        resetValues()
    }

    return (
        <React.Fragment>
            <Row style={{marginBottom: 16}}>
                <Col xs={2}>
                    <Select
                        label="Дата"
                        defaultOption={dateDefaultOption}
                        disabled={dateOptions.length === 0}
                        options={dateOptions}
                        value={date}
                        onChange={setDate}
                        hasError={errors.includes('date')}
                    />
                </Col>
                <Col xs={3}>
                    <Input
                        type="number"
                        placeholder="Введите стоимость"
                        label="Стоимость за штуку"
                        value={price}
                        onChange={setPrice}
                        hasError={errors.includes('price')}
                    />
                    <Notice>Стоимость парковки {defaultPrice} руб. в сутки</Notice>
                </Col>
                <Col xs={2}>
                    <InputNumber
                        label="Количество"
                        defaultValue={defaultQuantityOption}
                        value={quantity}
                        onChange={setQuantity}
                        hasError={errors.includes('quantity')}
                    />
                </Col>
                <Col xs={2}>
                    <CarNumberSelector
                        carNumberValue={carNumbers}
                        setCarNumberValue={setCarNumbers}
                        hasLabel
                        hasError={errors.includes('parking_car_numbers')}
                        carLimit={carLimit}/>
                    <Notice>Введите номер ТС и нажмите Enter</Notice>
                </Col>
                <Col xs={3}>
                    <Select
                        label="Метод оплаты"
                        defaultOption={defaultPaymentTypeOption}
                        options={paymentTypeList}
                        value={paymentType}
                        onChange={setPaymentType}
                        hasError={errors.includes('paymentType')}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={2} xsOffset={9}>
                    <ButtonGroup>
                        <Button
                            type="success"
                            onClick={handleSubmit}
                            style={{width: '100%'}}
                        >
                            Добавить парковку
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </React.Fragment>
    )
}
