import paymentTypes from '../../helpers/paymentTypes'
import {
  getPrintedDiscountedDayItemData,
  getPrintedDiscountedItemData,
  getPrintedItemData,
} from '../../helpers/item-selectors'
import { currency } from '../../helpers/converters'
import { createCheckLine } from './create-check-line'

const mapToPaymentName = {
  [paymentTypes.cash]: 'cash',
  [paymentTypes.card]: 'card',
}

export function getItemsCheckData(
  { days, services },
  { serviceNameField = 'service' } = {}
) {
  const lines = []

  const totals = {
    cash: 0,
    card: 0,
  }

  const createProcessFn = callback => item => {
    const { name, price, quantity, total, paymentType, nds_value } = callback(item)

    lines.push(
      createCheckLine({
        name,
        price,
        quantity,
        total,
        nds_value
      })
    )

    const paymentName = mapToPaymentName[paymentType]

    totals[paymentName] += Number(total)
  }

  const processDay = createProcessFn(day => {
    const { quantity = 1, total } = getPrintedDiscountedDayItemData(day)
    const { price } = getPrintedItemData(day)

    return {
      name: `Проживание за ${day.date}`,
      price,
      quantity,
      total,
      paymentType: day.payment_type,
    }
  })

  const processService = createProcessFn(service => {
    const { quantity, total } = getPrintedDiscountedItemData(service)
    const { price } = getPrintedItemData(service)

    return {
      name: `${service[serviceNameField]} за ${service.date}`,
      price,
      quantity,
      total,
      paymentType: service.payment_type,
      nds_value: service.nds_value
    }
  })

  for (let day of days) processDay(day)
  for (let service of services) processService(service)

  const printTotals = {
    cash: currency.toString(totals.cash),
    card: currency.toString(totals.card),
  }

  return {
    lines,
    totals,
    printTotals,
  }
}
