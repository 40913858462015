import React from 'react'
import classNames from 'classnames'
import {useIsSelected} from '../selection/use-is-selected'
import {isRoomOccupancyExtremelyLow, isRoomOccupancyLow} from "../../../reducers/grid/constants";

const EmptyCell = ({style, roomID, date, today, isRepair, isBusyByEmployee, status, count}) => {
    const isSelected = useIsSelected({room: roomID, date})

    const emptyCellClass = classNames({
        'tbody-cell': true,
        'today': date === today,
        'selected': isSelected,
        'repair': isRepair,
        'is-busy-by-employee': isBusyByEmployee,
        'yellow': isRoomOccupancyLow(count),
        'orange': isRoomOccupancyExtremelyLow(count)
    })

    return (
        <div
            style={style}
            className={emptyCellClass}
            data-room={roomID}
            data-date={date}
            data-is-empty={true}
    />
    )
}

export default EmptyCell
