import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import ButtonWithLoading from 'react-bootstrap-button-loader'
import { PrintFormButtons } from './print-form-buttons'

export const ModalTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: ${props => props.padding ? props.padding : 20}px;
`

export const ModalContent = styled.div`
  padding: 16px 20px;
  max-height: ${props => props.maxHeight}px;
  overflow-y: auto;
`

export const ModalHR = styled.hr`
  margin: 0;
  border-color: #e2e2e2;
`

export const ModalText = styled.div`
  margin: 0;
  padding: 16px 20px;
`

export const ModalControls = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: -10px;
  padding: 16px;
`

export const ModalControlsSectionGroup = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
`

const ModalControlsSectionBlock = styled.div`
  width: 50%;
  padding: 10px;
`

const ModalControlsSectionTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 10px 0;
`

const ModalControlsSectionButtons = styled(ModalControls)`
  padding: 0;
  justify-content: flex-start;
`

export const ModalControlsSection = ({ title, children }) => {
  return (
    <ModalControlsSectionBlock>
      <ModalControlsSectionTitle>{title}</ModalControlsSectionTitle>
      <ModalControlsSectionButtons>{children}</ModalControlsSectionButtons>
    </ModalControlsSectionBlock>
  )
}

export const ModalButton = styled(Button)`
  margin-right: 10px;
  margin-bottom: 10px;
`

export const ModalButtonWithLoading = styled(ButtonWithLoading)`
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: ${props => (props.loading ? 0 : '12px')};
`

export const ModalPrintButtons = () => (
  <PrintFormButtons style={{ marginRight: 10, marginBottom: 10 }} />
)

export const ModalSuccessMessage = styled.p`
  font-size: 12px;
  color: #5cb85c;
  padding: 10px 20px;
  margin: 0;
  margin-top: -10px;
`

export const ModalError = styled.p`
  font-size: 12px;
  color: #d9534f;
  padding: 10px 20px;
  margin: 0;
  margin-top: -10px;
`

export const ModalTextBlock = styled.div`
  padding: 16px 20px;
`
