import React, {useState} from "react";
import {callModal, callPrompt, modalTemplates} from "../../../dynamic-modal";
import generateGUID from "../../../../Modules/CheckPrintController/generateGUID";
import {kkmAPI} from "../../../../Modules/api/kkm-api";
import {ccAPI} from "../../../../Modules/api/ccAPI";
import {Notifications} from "../../../../Modules/Notifications";
import moment from "moment/moment";
import {rub} from "../../../../Modules/formatters/currency";
import {useSelector} from "react-redux";
import {settingsSelectors} from "../../../../Modules/settings";
import styled from 'styled-components'

const Item = styled.div`
    margin-bottom: 15px;
    display: flex;
    gap: 10px;
`


export const DepositBtn = ({
                               component: Component,
                               lcode,
                               booking_number,
                               reservation_pk,
                               is_deposit_exists,
                               deposit
                           }) => {
    const isDepositEnabled = useSelector(settingsSelectors.isDepositEnabled)
    const depositAmount = useSelector(settingsSelectors.depositAmount)

    const [isUsed, setUsed] = useState(is_deposit_exists)

    if (!isDepositEnabled) return <></>

    const getDeposit = async () => {

        await callPrompt({
            title: 'Депозит',
            text: [
                'Введите сумму депозита',
            ],
            initialValue: depositAmount,
            validators: [
                ...modalTemplates.prompt.defaultValidators,
                {
                    fn: value => !Number.isNaN(Number(value)),
                    message: 'Значение должно быть числом',
                },
                {
                    fn: value => Number(value) > 0,
                    message: 'Значение должно быть больше 0',
                },]
        }).then(async value => {
            setUsed(true)
            if (value) {
                await kkmAPI.getDeposit({
                    Amount: value,
                    IdCommand: generateGUID()
                }).then(async ({Error, UniversalID}) => {
                        if (!Error) {

                            const data = {
                                universal_id_for_deposit: UniversalID,
                                held_deposit_amount: value,
                                released_deposit_amount: 0,
                                lcode,
                                booking_number,
                                reservation_pk
                            }
                            await ccAPI.setDeposit(data).then(_ => {
                                ccAPI.updateReservation({pk: reservation_pk, data: {is_deposit_exists: true}})
                                Notifications.success('Успех')
                            })
                                .catch(_ => {
                                    Notifications.failure('Что-то пошло не так')
                                    setUsed(false)
                                })
                        } else setUsed(false)
                    }
                )
            }
        })
    }
    const showInfo = () => {
        const {created_at, held_deposit_amount, withdrawals, returned_deposit_amount, is_active, updated_at} = deposit
        callModal(
            modalTemplates.alert({
                title: 'Депозит',
                text: <div>
                    <Item>Депозит удержан <strong>{moment(created_at).format('DD.MM.YYYY')}</strong> в размере
                        <strong> {rub(held_deposit_amount)}</strong>.
                    </Item>
                    {withdrawals.length ?
                        <Item>
                            <div>Списания:</div>
                            <div>{withdrawals.map(({date, amount}, index) => <div
                                    key={date + amount + index}>
                                    <strong>{moment(date).format('DD.MM.YYYY')}</strong> - {rub(amount)}
                                </div>
                            )}</div>
                        </Item>
                        : <></>}
                    {!is_active &&
                        <Item>Депозит возвращен <strong>{moment(updated_at).format('DD.MM.YYYY')}</strong> в
                            размере
                            <strong> {rub(returned_deposit_amount)}</strong>.</Item>}
                </div>
            })
        )
    }

    const saveBefore = () => {
        callModal(
            modalTemplates.alert({
                title: 'Депозит',
                text: "Для удержания депозита сначала сохраните бронирование"
            }))
    }

    return (
        <>
            {reservation_pk ? <>{
                    is_deposit_exists ?
                        <Component
                            bsStyle="warning"
                            onClick={showInfo}
                        >
                            Депозит удержан
                        </Component>
                        : <Component
                            bsStyle="warning"
                            onClick={getDeposit}
                            disabled={isUsed}
                        >
                            Удержать депозит
                        </Component>
                }</>
                : <Component
                    bsStyle="warning"
                    onClick={saveBefore}
                >
                    Удержать депозит
                </Component>}
        </>
    )
}
