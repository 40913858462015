import {createSelector} from "reselect";
import {selectedKKT} from "../checks/selectors";

export const refundsSelector = state => state.refunds

export const items = state => refundsSelector(state).items

export const isLoaded = state => refundsSelector(state).isLoaded
export const isFailed = state => refundsSelector(state).isFailed
export const isLoading = state => refundsSelector(state).isLoading

export const filteredRefundsByKKT = createSelector(
    items, selectedKKT,
    (items, selectedKkt) => {
        return selectedKkt ? items.filter(({kkt}) => kkt === selectedKkt) : items
    })
