import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { paymentTypesNoBank } from '../../../../Modules/helpers/paymentTypes'
import { Select, Input } from '../../../form-stuff'
import { Button, ButtonGroup } from '../../../buttons'
import { defaultPaymentTypeOption } from '../../constants/default-values'
import { useDateSelectData } from '../../common/hooks/use-date-select-data'

export const Footer = ({ availableDates, actions, service }) => {
  const {
    date,
    setDate,
    resetDate,
    dateOptions,
    dateDefaultOption,
  } = useDateSelectData(availableDates)

  const [price, setPrice] = useState('')
  const [paymentType, setPaymentType] = useState('')

  const resetValues = () => {
    resetDate()
    setPrice('')
    setPaymentType('')
  }

  const [errors, setErrors] = useState([])
  const resetErrors = () => setErrors([])

  const handleSubmit = () => {
    resetErrors()

    const currentErrors = []

    if (!date) currentErrors.push('date')
    if (!price) currentErrors.push('price')
    if (!paymentType) currentErrors.push('paymentType')

    if (currentErrors.length > 0) {
      setErrors(currentErrors)
      return
    }

    actions.create({
      ...service,
      price,
      paymentType,
      date,
    })

    resetValues()
  }

  return (
    <React.Fragment>
      <Row style={{ marginBottom: 16 }}>
        <Col xs={3} xsOffset={3}>
          <Select
            label="Дата проживания"
            defaultOption={dateDefaultOption}
            options={dateOptions}
            value={date}
            onChange={setDate}
            hasError={errors.includes('date')}
          />
        </Col>
        <Col xs={3}>
          <Input
            type="number"
            placeholder="Введите стоимость"
            label="Стоимость"
            value={price}
            onChange={setPrice}
            hasError={errors.includes('price')}
          />
        </Col>
        <Col xs={3}>
          <Select
            label="Метод оплаты"
            defaultOption={defaultPaymentTypeOption}
            options={paymentTypesNoBank}
            value={paymentType}
            onChange={setPaymentType}
            hasError={errors.includes('paymentType')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3} xsOffset={9}>
          <ButtonGroup>
            <Button
              type="success"
              onClick={handleSubmit}
              style={{ width: '100%' }}
            >
              Добавить улучшение
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </React.Fragment>
  )
}
