import { createSelector } from 'reselect'
import {
    breakfastServiceName,
    earlyCheckInService, earlyCheckInService_legacy,
    lateCheckOutService, lateCheckOutService_legacy,
    livingServiceName,
    parkingServiceName, upgradeService
} from '../helpers/servicesNames'

export const everything = state => state.specialServiceTypes

export const items = state => everything(state).items

export const isLoading = state => everything(state).isLoading
export const isLoaded = state => everything(state).isLoaded
export const isFailed = state => everything(state).isFailed

export const itemsByPk = createSelector(items, items =>
  items.reduce((acc, item) => {
    acc[item.pk] = item
    return acc
  }, {})
)

export const itemsByName = createSelector(items, items =>
  items.reduce((acc, item) => {
    acc[item.name] = item
    return acc
  }, {})
)

export const breakfastService = createSelector(items, items =>
  items.find(service => service.name === breakfastServiceName)
)

export const livingService = createSelector(items, items =>
  items.find(service => service.name === livingServiceName)
)

export const earlyCheckinService = createSelector(items, items =>
  items.find(service => service.name === earlyCheckInService || service.name === earlyCheckInService_legacy)
)

export const lateCheckoutService = createSelector(items, items =>
  items.find(service => service.name === lateCheckOutService || service.name === lateCheckOutService_legacy)
)

export const parkingService = createSelector(items, items =>
  items.find(service => service.name === parkingServiceName)
)
export const upgrade = createSelector(items, items =>
    items.find(service => service.name === upgradeService)
)
export const common = createSelector(items, items =>
  items.filter(
    service => !('bonus_rate' in service) && service.name !== livingServiceName
  )
)

export const special = createSelector(items, items =>
  items.filter(
    service => service.is_listed_in_services_list
  )
)
