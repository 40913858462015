import { request } from './request'

export const executeCommand = data =>
  request({
    url: '/Execute',
    method: 'post',
    data,
  }).then(res => res.data)

export const print = options =>
  executeCommand({
    Command: 'RegisterCheck',
    ...options,
  })

export const getKKTState = () =>
  executeCommand({
    Command: 'GetDataKKT',
  })

export const openShift = ({ CashierName, CashierVATIN }) =>
  executeCommand({
    Command: 'OpenShift',
    CashierName,
    CashierVATIN,
  })

export const closeShift = ({ CashierName, CashierVATIN }) =>
  executeCommand({
    Command: 'CloseShift',
    CashierName,
    CashierVATIN,
  })

export const getDeposit = options =>
    executeCommand({
        Command: 'AuthorisationByPaymentCard',
        ...options,
    })

export const refundDeposit = options =>
    executeCommand({
        Command: 'CancelAuthorisationByPaymentCard',
        ...options,
    })

export const releaseDeposit = options =>
    executeCommand({
        Command: 'AuthConfirmationByPaymentCard',
        ...options,
    })

