import React from 'react'
import {EditSection} from '../../common/EditSection'
import {Content} from './components/Content'
import {Footer} from './components/Footer'

export const Upgrades = ({
                             items,
                             availableDates,
                             validationErrors,
                             actions,
                             availableActions = {
                                 create: true,
                                 update: true,
                                 copy: true,
                                 delete: true,
                                 refund: false,
                             },
                             upgradeService
                         }) => {
    const content = items.length > 0 && (
        <Content
            items={items}
            availableDates={availableDates}
            actions={actions}
            validationErrors={validationErrors}
            availableActions={availableActions}
        />
    )

    const footer = availableActions.create && (
        <Footer availableDates={availableDates} actions={actions} service={upgradeService}/>
    )

    return <EditSection name="Улучшения" content={content} footer={footer}/>
}
