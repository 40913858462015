import * as types from './types'

const initialState = {
  items: [],
  itemsCC: [],
  isLoading: false,
  isLoaded: false,
  isFailed: false,
  error: null,
  selectedKKT: ""
}

export const reducer = (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case types.GET_CHECKS_REQUEST:
      return {
        ...state,
        items: [],
        isLoading: true,
        isLoaded: false,
        isFailed: false,
        error: null,
      }

    case types.GET_CHECKS_SUCCESS:
      return {
        ...state,
        items: payload.items,
        isLoading: false,
        isLoaded: true,
      }

    case types.GET_CHECKS_BY_UUID:
      return {
        ...state,
        itemsCC: payload.itemsCC,
        isLoading: false,
        isLoaded: true,
      }

    case types.GET_CHECKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        error: payload.error,
      }

    case types.SET_SELECTED_KKT:
      return {
        ...state,
        ...payload
      }

    case types.RESET:
      return initialState

    default:
      return state
  }
}
