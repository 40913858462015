import React, {Component, Fragment} from 'react'
import qs from 'qs'
import {Button, Col, Row} from 'react-bootstrap'
import history from '../../../Modules/helpers/history'
import Checkbox from '../../common/Checkbox'
import Day from '../common/Day'
import {
    ModalButton,
    ModalButtonWithLoading,
    ModalContent,
    ModalControls,
    ModalControlsSection,
    ModalControlsSectionGroup,
    ModalError,
    ModalHR,
    ModalPrintButtons,
    ModalSuccessMessage,
    ModalTitle,
} from '../../common/ModalParts'
import Rooms from './Rooms'
import Modal from 'react-modal'
import LoadingModal from '../../common/LoadingModal'
import styled from 'styled-components'
import BottomBarButtons, {BarButton, BarButtonGroup,} from '../common/BottomBarButtons'
import PrintServerErrorModal from '../../common/PrintServerErrorModal'
import Logs from '../../../Modules/Logs'
import {EnhancedCheckLines} from '../../CheckLines'
import {dayTypes} from '../../../Modules/helpers/types'
import {EditSection} from '../../common/EditSection'
import {ConnectedServices} from '../../Services/Services'
import {ConnectedValidationErrorsModal} from '../common/ValidationErrorsModal'
import {ConnectedUpgrades} from '../../Services/Upgrades'
import {ConnectedCheckTimes} from '../../Services/CheckTimes'
import {LogsAPI} from '../../../Modules/logs-api'
import {PrintBlankButton} from '../../PrintBlankButton'
import {handleWriteCardClick} from '../../../Modules/helpers/handleWriteCardClick'
import {callModal, modalTemplates} from '../../dynamic-modal'
import {PassportFieldGroup} from '../../PassportFieldGroup'
import {PrintFormButtons} from '../../common/print-form-buttons'
import {ReservationLayout} from '../common/ReservationLayout'
import {ModalLoyaltyInfo} from '../../common/ModalLoyaltyInfo'
import {Loyalty} from '../../loyalty'
import {RangeDatePicker} from '../../common/DatePicker'
import {Breakfasts} from '../../Services/Breakfasts'
import {Parking} from '../../Services/Parking'
import {DebouncedTextField} from '../../common/DebouncedTextField'
import {TotalSumm} from '../common/TotalSumm'
import {useSelector} from 'react-redux'
import {settingsSelectors} from '../../../Modules/settings'
import moment from 'moment'
import {ConnectedPaymentMethodErrorsModal} from '../common/PaymentMethodErrorsModal/ConnectedPaymentMethodErrorsModal'
import {DepositBtn} from "../common/Deposit/DepositBtn";

const StyledDescription = props => (
    <p style={{marginBottom: 25, fontSize: 14, fontWeight: 500}}>
        {props.children}
    </p>
)

const RefundModal = props => {
    return (
        <Modal isOpen={props.isOpen}>
            <ModalTitle>Невозвратная бронь</ModalTitle>
            <ModalHR/>
            <ModalContent>Укажите причину создания брони вручную</ModalContent>
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="primary"
                    onClick={() => {
                        props.hideRefundModal()
                        props.confirmAndSave()
                    }}
                    style={{marginLeft: 'auto'}}
                >
                    Релокация
                </ModalButton>
                <ModalButton
                    bsStyle="primary"
                    onClick={() => {
                        props.hideRefundModal()
                        props.showRefundActionModal()
                    }}
                >
                    Другая причина
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

const RefundActionModal = props => {
    return (
        <Modal isOpen={props.isOpen}>
            <ModalTitle>Другая причина</ModalTitle>
            <ModalHR/>
            <ModalContent>
                Вручную можно создать бронь только при релокации. Для внесения брони,
                которую вы хотите создать, перейдите в меню Wubook, выберите нужную дату
                заезда и бронь для автозаселения.
                <br/>
                <br/>
                Создавая бронь вручную вы подтверждаете, что ее нет в списке
                автозаселения нужной даты заезда.
            </ModalContent>
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="danger"
                    onClick={() => {
                        LogsAPI.pressAction('Создать бронь вручную')
                        props.hideRefundActionModal()
                        props.confirmAndSave()
                    }}
                    style={{marginLeft: 'auto'}}
                >
                    Создать бронь вручную
                </ModalButton>
                <ModalButton
                    bsStyle="success"
                    onClick={() => {
                        LogsAPI.pressAction('Перейти в Wubook')
                        history.push('/wubook')
                    }}
                >
                    Перейти в Wubook
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

const SuccessModal = ({
                          isOpen,
                          hideModal,
                          isPrinterOn,
                          printCheckAgain,
                          showWritecardModal,
                          reservation_pk,
                          booking_number,
                          lcode,
                      }) => {
    const isDoorLocks = useSelector(settingsSelectors.isDoorLocks)
    return (
        <Modal isOpen={isOpen} style={{content: {width: 900, maxWidth: 900}}}>
            <ModalTitle>Успех</ModalTitle>
            <ModalHR/>
            <ModalContent>
                <p>Бронь была создана.</p>
                <ModalLoyaltyInfo/>
            </ModalContent>
            <ModalHR/>
            <ModalControlsSectionGroup>
                <ModalControlsSection title="Печать форм">
                    <ModalPrintButtons/>
                </ModalControlsSection>

                <ModalControlsSection title="Печать бланка">
                    <PrintBlankButton component={ModalButton}/>
                </ModalControlsSection>

                {isPrinterOn && (
                    <ModalControlsSection title="Принтер">
                        <ModalButton bsStyle="primary" onClick={printCheckAgain}>
                            Повторная печать чека
                        </ModalButton>
                    </ModalControlsSection>
                )}

                {isDoorLocks && <ModalControlsSection title="Действия с картами">
                    <ModalButton
                        bsStyle="primary"
                        onClick={() => {
                            handleWriteCardClick({
                                showWriteCardModal: () => showWritecardModal(),
                            })
                        }}
                    >
                        Записать карту
                    </ModalButton>
                </ModalControlsSection>}

                {reservation_pk && <ModalControlsSection title="Депозиты">
                    <DepositBtn component={BarButton}
                                lcode={lcode}
                                booking_number={booking_number}
                                reservation_pk={reservation_pk}
                    /></ModalControlsSection>}

                <ModalControlsSection title="Действия с окном">
                    <ModalButton
                        bsStyle="danger"
                        onClick={() => {
                            hideModal()
                            history.push('/')
                        }}
                    >
                        Закрыть
                    </ModalButton>
                </ModalControlsSection>
            </ModalControlsSectionGroup>
        </Modal>
    )
}

const ErrorModal = props => {
    return (
        <Modal isOpen={props.isOpen}>
            <ModalTitle>Ошибка!</ModalTitle>
            <ModalHR/>
            <ModalContent>
                Один или несколько новых дней невозможно добавить, так как они
                принадлежат брони {props.errorPK}
            </ModalContent>
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="danger"
                    style={{marginLeft: 'auto'}}
                    onClick={props.hideModal}
                >
                    Закрыть
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

const FDParagraph = styled.p`
    margin-bottom: 20px;
`

const FDItem = styled.p`
    padding-left: 15px;
`

const FDMessage = styled.p`
    margin-top: 20px;
`

const FDQuestion = styled.p`
    margin-top: 20px;
    font-weight: 700;
`

const FoundDuplicatesModal = props => {
    const {duplicatesData} = props

    const isMultiple = duplicatesData.duplicates.length > 1

    return (
        <Modal isOpen={props.isOpen}>
            <ModalTitle>Ошибка!</ModalTitle>
            <ModalHR/>
            <ModalContent>
                <FDParagraph>
                    {isMultiple ? 'Найдены брони' : 'Найдена бронь'} с таким же номером:
                </FDParagraph>
                {duplicatesData.duplicates.map((d, idx) => (
                    <FDItem key={idx}>
                        Дата заселения: {d.start} | Комната: {d.room || 'Не выбрана'}
                    </FDItem>
                ))}
                {!isMultiple && <FDMessage>{duplicatesData.message}</FDMessage>}
                <FDQuestion>
                    Создавая бронь, вы подтверждаете, что проверили данную бронь на
                    дублирование
                </FDQuestion>
            </ModalContent>
            <ModalHR/>
            <ModalControls>
                <ModalButton bsStyle="success" onClick={props.hideModal}>
                    Не создавать бронь
                </ModalButton>
                <ModalButton
                    bsStyle="danger"
                    onClick={() => {
                        LogsAPI.event('Предупреждение о наличии дубликата проигнорировано')
                        props.confirmDuplicate()
                        props.saveNewReservation()
                    }}
                >
                    Создать бронь
                </ModalButton>
            </ModalControls>
        </Modal>
    )
}

class WriteCardModal extends Component {
    state = {
        fetching: false,
        successMessage: null,
        isWrited: false,
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} style={{content: {width: 540}}}>
                <ModalTitle>Запись карты</ModalTitle>
                <ModalHR/>
                <ModalContent>Приложите карту</ModalContent>
                {this.state.successMessage ? (
                    <ModalSuccessMessage>{this.state.successMessage}</ModalSuccessMessage>
                ) : null}
                {this.props.error ? <ModalError>{this.props.error}</ModalError> : null}
                <ModalHR/>
                <ModalControls>
                    <ModalButton
                        bsStyle="danger"
                        onClick={() => {
                            LogsAPI.pressAction('Закрыть диалог записи карт')
                            this.props.hideModal()
                            this.props.hideError()
                        }}
                    >
                        {this.state.isWrited ? 'Завершить' : 'Отмена'}
                    </ModalButton>
                    <ModalButtonWithLoading
                        loading={this.state.fetching}
                        bsStyle="success"
                        onClick={() => {
                            LogsAPI.pressAction('Запись карты')
                            this.props.hideError()
                            this.setState({fetching: true, successMessage: null})
                            this.props
                                .action()
                                .then(
                                    (res) => {
                                        LogsAPI.event(`Карта успешно записана (${res.meta})`)
                                        this.setState({
                                            successMessage: 'Карта успешно записана!',
                                            isWrited: true,
                                        })
                                    },
                                    () => {
                                        LogsAPI.error(`Неудачная запись карты: ${this.props.error}`)
                                    }
                                )
                                .then(() => {
                                    this.setState({fetching: false})
                                })
                        }}
                    >
                        {this.state.isWrited ? 'Записать еще' : 'Записать'}
                    </ModalButtonWithLoading>
                </ModalControls>
            </Modal>
        )
    }
}

class NewReservation extends Component {
    state = {
        notPayedConfirmed: false,
        initialTotal: 0,
        activeModals: [],
    }

    async componentDidMount() {
        const q = qs.parse(this.props.location.search.slice(1))
        await this.props.loadServices()
        await this.props.createNewReservation(q.room, q.roomType, q.start, q.end)
        await this.props.getRegistrationForms()

        // hold for 200 ms to show modal after the page loads
        setTimeout(() => {
            this.showModal('enter-phone')
        }, 200)
    }

    componentWillUnmount() {
        this.props.clearReservation()
        Logs.clearTemp()
    }

    showModal = name =>
        this.setState(prev => ({
            activeModals: prev.activeModals.concat(name),
        }))

    hideModal = name =>
        this.setState(prev => ({
            activeModals: prev.activeModals.filter(m => m !== name),
        }))

    onDatesChange = (startDate, endDate) => {
        const fSD = startDate.format('YYYY-MM-DD')
        const fED = endDate.format('YYYY-MM-DD')

        if (this.props.start !== fSD) {
            this.props.onStartChange(fSD)
        }

        if (this.props.end !== fED) {
            this.props.onEndChange(fED)
        }
    }

    calcTotal = () => {
        const {payedDays} = this.props

        let totalToPay = 0.0
        let totalPayed = 0.0
        this.props.reservedDays
            .filter(d => d.type !== dayTypes.payed)
            .forEach(d => {
                totalToPay +=
                    isNaN(parseFloat(d.price)) ||
                    d.payment_date === '' ||
                    d.payment_type === ''
                        ? 0
                        : parseFloat(d.price)
            })
        this.props.allServices
            .filter(s => s.payed !== true)
            .forEach(s => {
                totalToPay +=
                    isNaN(parseFloat(s.price * s.quantity)) || s.payment_type === ''
                        ? 0
                        : parseFloat(s.price * s.quantity)
            })
        payedDays.forEach(d => {
            totalPayed += isNaN(parseFloat(d.price)) ? 0 : parseFloat(d.price)
        })
        this.props.allServices
            .filter(s => s.payed === true)
            .forEach(s => {
                totalPayed += isNaN(parseFloat(s.price * s.quantity))
                    ? 0
                    : parseFloat(s.price * s.quantity)
            })

        return {
            toPay: totalToPay,
            payed: totalPayed,
        }
    }

    confirmNotPayed = () => {
        this.setState({notPayedConfirmed: true})
    }

    renderNewDays = modes => {
        const {newDays} = this.props

        return (
            <EditSection
                name="Новые дни"
                content={
                    newDays.length > 0 && (
                        <React.Fragment>
                            <StyledDescription>
                                Укажите количество гостей для корректного отображения цены
                            </StyledDescription>

                            {newDays.map(day => (
                                <Day
                                    key={day.id}
                                    day={day}
                                    price={day.price}
                                    method={day.payment_type}
                                    onDayPriceChange={value =>
                                        this.props.onDayPriceChange(day.id, value)
                                    }
                                    onDayPaymentChange={value =>
                                        this.props.onDayMethodChange(day.id, value)
                                    }
                                    onDayPayedChange={value =>
                                        this.props.onDayPayedChange(day.id, value)
                                    }
                                    payed={!!day.payment_date}
                                    modes={modes}
                                    notPayedConfirmed={this.state.notPayedConfirmed}
                                    confirmNotPayed={this.confirmNotPayed}
                                    validationErrors={this.props.daysValidationErrors}
                                />
                            ))}
                        </React.Fragment>
                    )
                }
                footer={
                    <React.Fragment>
                        {newDays.length > 1 && (
                            <div style={{marginBottom: 10}}>
                                <Fragment>
                                    <Button
                                        bsStyle="primary"
                                        onClick={() => this.props.onDaySummCopy()}
                                    >
                                        Копировать цену с первого дня
                                    </Button>
                                    <Button
                                        style={{marginLeft: 5}}
                                        bsStyle="primary"
                                        onClick={() => this.props.onDayMethodCopy()}
                                    >
                                        Копировать метод с первого дня
                                    </Button>
                                </Fragment>
                            </div>
                        )}
                        <div>
                            <Button
                                onClick={() => this.props.onReservationDayAdd()}
                                bsStyle="success"
                            >
                                Добавить сутки
                            </Button>
                            {newDays.length > 1 ? (
                                <Button
                                    onClick={() => this.props.onReservationDayRemove()}
                                    style={{marginLeft: 5}}
                                    bsStyle="danger"
                                >
                                    Убрать сутки
                                </Button>
                            ) : null}
                        </div>
                    </React.Fragment>
                }
            />
        )
    }

    setInitialTotal = () => {
        this.setState({initialTotal: this.calcTotal()})
    }

    render() {
        const {modes, generalValidationErrors} = this.props

        return (
            <>
                <ReservationLayout
                    left={
                        <>
                            <EditSection
                                name="Общие данные"
                                content={
                                    <Row>
                                        <Col xs={4}>
                                            <Rooms rooms={this.props.rooms}/>
                                            <DebouncedTextField
                                                type="number"
                                                label="Количество взрослых гостей"
                                                value={this.props.guestsNumber}
                                                onChange={this.props.onGuestsNumberChange}
                                                error={generalValidationErrors['guests_number']}
                                                containerStyle={{marginBottom: 10}}
                                            />
                                            <DebouncedTextField
                                                label="ФИО гостя"
                                                placeholder="Иванов Иван Иванович"
                                                value={this.props.name}
                                                onChange={this.props.onNameChange}
                                                error={generalValidationErrors['guest_name']}
                                                containerStyle={{marginBottom: 10}}
                                            />
                                            <PassportFieldGroup/>
                                            <DebouncedTextField
                                                label="Номер брони"
                                                placeholder="123456789"
                                                value={this.props.bookingNumber}
                                                onChange={this.props.onBookingNumberChange}
                                                containerStyle={{marginBottom: 10}}
                                            />
                                            <DebouncedTextField
                                                label="Телефон гостя"
                                                placeholder="+71234567890"
                                                value={this.props.phone}
                                                onChange={this.props.onPhoneChange}
                                                error={generalValidationErrors['guest_phone']}
                                                containerStyle={{marginBottom: 10}}
                                            />
                                            <DebouncedTextField
                                                label="Почта гостя"
                                                placeholder="ivanov@gmail.com"
                                                value={this.props.mail}
                                                onChange={this.props.onMailChange}
                                                containerStyle={{marginBottom: 10}}
                                                error={generalValidationErrors['guest_mail']}
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <DebouncedTextField
                                                componentType="textarea"
                                                label="Примечание"
                                                placeholder="Полезное примечание"
                                                value={this.props.note}
                                                onChange={this.props.onNoteChange}
                                                containerStyle={{marginBottom: 10}}
                                            />
                                        </Col>
                                        <Col xs={5}>
                                            <Checkbox
                                                name="Оплата"
                                                title="Бронь оплачена полностью"
                                                value={this.props.payed}
                                                onChange={async value => {
                                                    if (this.state.notPayedConfirmed) {
                                                        return this.props.onReservationPayedChange(value)
                                                    }

                                                    const {action} = await callModal(
                                                        modalTemplates.confirm({
                                                            text:
                                                                'Вы действительно хотите создать бронь без оплаты?',
                                                        })
                                                    )

                                                    if (
                                                        action !== modalTemplates.confirm.actions.confirm
                                                    ) {
                                                        return
                                                    }

                                                    this.confirmNotPayed()
                                                    return this.props.onReservationPayedChange(value)
                                                }}
                                                formStyle={{marginBottom: 15}}
                                            />
                                            <Checkbox
                                                name="Возвратность"
                                                title="Бронь невозвратная"
                                                value={this.props.hasRefund}
                                                onChange={() =>
                                                    this.props.onRefundChange(!this.props.hasRefund)
                                                }
                                                formStyle={{marginBottom: 15}}
                                            />
                                            <RangeDatePicker
                                                startLabel="Дата заезда"
                                                endLabel="Дата выезда"
                                                start={this.props.start}
                                                end={this.props.end}
                                                startMinDate={
                                                    moment(new Date())
                                                        .add(-1, 'day')
                                                        .toDate()
                                                }
                                                endMinDate={
                                                    moment(this.props.start)
                                                        .add(1, 'day')
                                                        .toDate()
                                                }
                                                onStartChange={this.props.onStartChange}
                                                onEndChange={this.props.onEndChange}
                                            />
                                        </Col>
                                    </Row>
                                }
                            />

                            {this.props.guestsNumber > 0 && this.renderNewDays(modes)}

                            <ConnectedCheckTimes
                                availableActions={{
                                    create: true,
                                    update: true,
                                    copy: true,
                                    delete: true,
                                    refund: false,
                                }}
                            />
                            <ConnectedServices
                                availableActions={{
                                    create: true,
                                    update: true,
                                    copy: true,
                                    delete: true,
                                    refund: false,
                                }}
                            />
                            <ConnectedUpgrades
                                availableActions={{
                                    create: true,
                                    update: true,
                                    copy: true,
                                    delete: true,
                                    refund: false,
                                }}
                            />
                            <Breakfasts
                                availableActions={{
                                    create: true,
                                    update: true,
                                    copy: true,
                                    delete: true,
                                    refund: false,
                                }}
                            />
                            <Parking
                                availableActions={{
                                    create: true,
                                    update: true,
                                    copy: true,
                                    delete: true,
                                    refund: false,
                                }}
                            />
                        </>
                    }
                    right={
                        <>
                            <EnhancedCheckLines/>
                            <Loyalty/>
                        </>
                    }
                    toolbar={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                backgroundColor: '#fff',
                                boxShadow: '0 -2px 5px 0 rgba(0, 0, 0, .27)',
                                padding: 25,
                            }}
                        >
                            <TotalSumm/>
                            <BottomBarButtons>
                                <PrintFormButtons
                                    buttonGroupComponent={BarButtonGroup}
                                    style={{marginRight: 20}}
                                />
                                <PrintBlankButton component={BarButton}/>
                                <BarButton
                                    style={{float: 'right'}}
                                    bsStyle="success"
                                    onClick={() => this.props.saveNewReservation(false)}
                                    disabled={this.props.isSaving}
                                >
                                    Создать
                                </BarButton>
                            </BottomBarButtons>
                        </div>
                    }
                />
                <SuccessModal
                    isOpen={this.props.showSuccessModal}
                    hideModal={this.props.hideSuccessModal}
                    pk={this.props.reservation.pk}
                    reservation={this.props.reservation}
                    settings={this.props.settings}
                    showWritecardModal={() => {
                        LogsAPI.pressAction('Открыть диалог записи карт')
                        this.showModal('writecard')
                    }}
                    isPrinterOn={this.props.isPrinterOn}
                    printCheckAgain={this.props.printCheckAgain}
                    isLoyaltyEnabled={this.props.isLoyaltyEnabled}
                    isLoyaltyUsed={this.props.isLoyaltyUsed}
                    loyaltyPurchaseData={this.props.loyaltyPurchaseData}
                    lcode={this.props.lcode}
                    booking_number={this.props.bookingNumber}
                    reservation_pk={this.props.pk}
                    is_deposit_exists={this.props.is_deposit_exists}
                    deposit={this.props.deposit}
                />
                <RefundModal
                    isOpen={this.props.isRefundModalOpened}
                    confirmAndSave={() => {
                        this.props.confirmRefund()
                        this.props.saveNewReservation()
                    }}
                    hideRefundModal={this.props.hideRefundModal}
                    showRefundActionModal={this.props.showRefundActionModal}
                />
                <RefundActionModal
                    isOpen={this.props.isRefundActionModalOpened}
                    confirmAndSave={() => {
                        this.props.confirmRefund()
                        this.props.saveNewReservation()
                    }}
                    hideRefundActionModal={this.props.hideRefundActionModal}
                />
                <ErrorModal
                    isOpen={this.props.showErrorModal}
                    errorPK={this.props.errorPK}
                    hideModal={this.props.hideErrorModal}
                />
                <ConnectedValidationErrorsModal/>
                <ConnectedPaymentMethodErrorsModal/>
                <PrintServerErrorModal
                    isOpen={this.props.isPrintServerErrorModalActive}
                    hideModal={this.props.hidePrintServerError}
                    tryAgainAction={() => this.props.saveReservation({modes})}
                />
                <WriteCardModal
                    isOpen={this.state.activeModals.includes('writecard')}
                    hideModal={() => this.hideModal('writecard')}
                    action={this.props.writeCard}
                    error={this.props.writecardError}
                    hideError={this.props.clearWritecardError}
                    pk={this.props.reservation.pk}
                />
                <FoundDuplicatesModal
                    isOpen={
                        this.props.isDuplicatesFound && !this.props.isDuplicateConfirmed
                    }
                    duplicatesData={this.props.duplicatesData}
                    confirmDuplicate={this.props.confirmDuplicate}
                    saveNewReservation={this.props.saveNewReservation}
                    hideModal={this.props.clearDuplicates}
                />
                <LoadingModal isOpen={this.props.isLoading}/>
            </>
        )
    }
}

export default NewReservation
