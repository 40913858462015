// export function createCheckLine({name, price, quantity, total, nds_value = -1}) {
export function createCheckLine({name, price, quantity, total, nds_value }) {
    return {
        Register: {
            Name: name,
            Quantity: quantity,
            Price: price,
            Amount: total,
            Tax:  nds_value ? nds_value : -1,
            SignCalculationObject: 4,
            SignMethodCalculation: 4,
            MeasurementUnit: 'шт',
        },
    }
}
