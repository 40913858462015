import store from '../../../../store'
import { checkKKMShift } from '../../lib/check-kkm-shift'
import { kkmAPI } from '../../../api/kkm-api'
import { getPrintData } from './get-print-data'
import { loyaltyPurchaseSelectors } from '../../../loyalty-purchase'
import { loyaltyErrors } from '../../../api/loyaltyAPI/constants/loyaltyErrors'
import { Notifications } from '../../../Notifications'
import { purchasePrintHandler } from '../purchase'
import {
  loyaltyClientActions,
  loyaltyClientSelectors,
} from '../../../loyalty-client'
import { confirmWithCode } from '../../../helpers/loyalty/confirm-with-code'
import { confirmationCodeFailure } from '../../util-callbacks'
import { callModal, modalTemplates } from '../../../../Components/dynamic-modal'
import moment from 'moment'
import { sessionSelectors } from '../../../session'
import { logPrintObject } from '../../lib/log-print-object'
import { createCallbacksHelpers } from '../../lib/create-callbacks-helpers'

export const loyaltyPurchasePrintHandler = async options => {
  const {
    modes,
    printScopes = { days: true, services: true },
    confirmEmail,
    validateReservation = async () => true,
    prepareReservation,
    beforePrint = () => {},
    afterPrint = () => {},
    onSuccess = () => {},
    onFailure = () => {},
    onPrintFinish = () => {},
    onCancel = () => {},
    onFinish = () => {},
    whenCheckPrinterIsDisabled = () => {},
    onShiftCheckFailure = ({ shiftCheckError }) => {
      callModal(
        modalTemplates.alert({
          title: 'Ошибка',
          text: `Не удалось проверить смену KKM: \n${shiftCheckError}`,
        })
      )
    },
    onLoyaltyFailure = () => {},
    onCodeConfirmationFailure = confirmationCodeFailure,
  } = options

  const isValid = await validateReservation()
  if (!isValid) {
    return finish([onCancel])
  }

  await confirmEmail()
  const { addCallbacksData, call, finish } = createCallbacksHelpers({
    onFinish,
  })

  let state = store.getState()

  const phoneNumber = loyaltyClientSelectors.phoneNumber(state)
  const usedBonuses = loyaltyPurchaseSelectors.usedBonuses(state)

  state = store.getState() // update state

  const reservation = await prepareReservation({
    reservation: state.reservation,
    modes,
    adminName: sessionSelectors.adminName(state),
  })

  const isPrinterOn = state.settings.printerIsOn
  const sendReceiptToEmail =
    state.settings.sendReceiptToEmail && reservation.sendConfirmToEmail

  const printData = await getPrintData({
    state,
    reservation,
    printScopes,
    isPrinterOn,
    sendReceiptToEmail
  })

  addCallbacksData(printData)
  addCallbacksData({
    wasPrinted: false,
    printResult: null,
  })

  if (printData.wasCanceled) {
    return finish([onCancel])
  }

  if (usedBonuses > 0) {
    const wasConfirmedWithCode = await confirmWithCode({ phoneNumber })

    if (!wasConfirmedWithCode) {
      return finish([onCodeConfirmationFailure])
    }
  }

  const { loyaltyActions } = printData

  const setPurchaseRes = await loyaltyActions.set()
  const { ticket } = setPurchaseRes

  if (setPurchaseRes.errorCode) {
    const loyaltyError = loyaltyErrors[setPurchaseRes.errorCode]

    const { action } = await callModal(
      modalTemplates.confirm({
        title: 'Ошибка лояльности',
        text: `Не удалось выполнить пречек: ${loyaltyError}.`,
        buttons: [
          'Отменить сохранение брони',
          {
            text: 'Продолжить без лояльности',
            color: 'warning',
            action: modalTemplates.confirm.actions.confirm,
          },
        ],
      })
    )

    // repeat saving using the common purchase print handler
    if (action === modalTemplates.confirm.actions.confirm) {
      return purchasePrintHandler(options)
    }

    addCallbacksData({
      loyaltyError,
    })

    return finish([onLoyaltyFailure])
  }

  if (!isPrinterOn) {
    await loyaltyActions.confirm(ticket)
    return finish([whenCheckPrinterIsDisabled])
  }
  let printCashierInfo = {}
  try {
    printCashierInfo= await checkKKMShift(state)
  } catch (err) {
    addCallbacksData({
      shiftCheckError: err.message,
    })

    return finish([onShiftCheckFailure])
  }

  let wasPrinted = true
  let printResult = null
  let printDateObj = moment()

  if (beforePrint) {
    await call(beforePrint)
  }

  if (printData.shouldPrint) {
    logPrintObject(printData.printObject)

    try {
      printResult = await kkmAPI.print(printData.printObject)
      printDateObj = moment()
      wasPrinted = !printResult.Error
    } catch {
      printResult = { Error: 'Неизвестная ошибка' }
      wasPrinted = false
    }
  }

  addCallbacksData({
    wasPrinted,
    printResult,
    printDateObj,
    printCashierInfo
  })

  if (afterPrint) {
    await call(afterPrint)
  }

  if (wasPrinted) {
    await loyaltyActions.confirm(ticket)

    await call(onSuccess)
  } else {
    store.dispatch(loyaltyClientActions.reset())

    await loyaltyActions.discard(ticket)

    await call(onFailure)

    Notifications.failure(
      'Бонусы не были применены, так как произошла ошибка при сохранении брони'
    )
  }

  await finish([onPrintFinish])
}
