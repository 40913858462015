import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { withPrintData } from '../HOCs/withPrintData'
import { ChecksStats } from '../../Components/DayTotals/features/MainPage/components/ChecksStats'
import { ChecksTable } from '../../Components/DayTotals/features/MainPage/components/ChecksTable'
import { RefundsTable } from '../../Components/DayTotals/features/MainPage/components/RefundsTable'
import { ThemeProvider } from '../../Components/DayTotals/ui/theme'
import { PrintInfo, PrintInfoLine } from './components/print-info'
import { Pages } from '../common/Pages'
import { Page } from '../common/Page'

const DayTotalsView = ({ adminName, printData }) => {
  const { checks, checksStats, refunds } = printData
  const date = moment()

  return (
    <ThemeProvider>
      <Pages>
        <Page>
          <PrintInfo>
            <PrintInfoLine>Админ: {adminName}</PrintInfoLine>
            <PrintInfoLine>Дата: {date.format('DD.MM.YYYY')}</PrintInfoLine>
            <PrintInfoLine>Время: {date.format('HH:mm:ss')}</PrintInfoLine>
          </PrintInfo>
          <ChecksStats checks={checks} checksStats={checksStats} />
          <ChecksTable items={checks} checksStats={checksStats} />
          <RefundsTable items={refunds} />
        </Page>
      </Pages>
    </ThemeProvider>
  )
}

const mapStateToProps = (state, ownProps) => ({
  adminName: state.session.admin,
  ...ownProps,
})

const enhance = compose(
  withPrintData('DAY_TOTALS_DATA'),
  connect(mapStateToProps)
)

export const DayTotals = enhance(DayTotalsView)
