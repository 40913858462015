import paymentTypes from '../../helpers/paymentTypes'
import {getPrintedDiscountedItemPrice, getPrintedDiscountedItemTotal,} from '../../helpers/item-selectors'
import {currency} from '../../helpers/converters'
import {sendBugReport} from '../../bug-report/send'
import {LogsAPI} from '../../logs-api'
import {createServiceTransaction} from "../../api/ccAPI/requests";
import {upgradeService, upgradeService_legacy} from "../../helpers/servicesNames";

function getTotal(service) {
    const total = getPrintedDiscountedItemTotal(service)
    return currency.toString(total)
}

function getPrice(service) {
    const price = getPrintedDiscountedItemPrice(service)
    return currency.toString(price)
}

function getSpecialServiceId(serviceName, specialServices) {
    const specialService = specialServices.find(
        service => service.name === serviceName
    )

    return specialService?.pk ?? ''
}

const transactionItemsMappers = {
    sell: (services, specialServices) => services.map(service => {
        let data = {
            uuid: service.uuid,
            item_name: service.service,
            amount: getPrice(service),
            qty: service.quantity,
            total_amount: getTotal(service),
            special_service_id: getSpecialServiceId(service.service, specialServices) || service.special_service_id,
        }
        if (service.service_type === 'parking') {
            data = {
                ...data,
                parking_date: service.date,
                parking_car_numbers: service.parking_car_numbers
            }
        }
        return data
    }),
    refund: (services, specialServices) =>
        services.map(service => {
            let data = {
                uuid: service.uuid,
                item_name: service.name,
                amount: getPrice(service),
                qty: service.quantity,
                total_amount: getTotal(service),
                special_service_id: getSpecialServiceId(service.service, specialServices) || service.special_service_id,
            }
            if (service.service_type === 'parking') {
                data = {
                    ...data,
                    parking_date: service.date,
                    parking_car_numbers: service.parking_car_numbers
                }
            }
            return data
        }),
}

function getTransactionServices({checkType, services, printedServices}) {
    const isPrinted = printedServices.reduce((acc, service) => {
        acc[service.id] = true
        return acc
    }, {})

    const notUpgrades = services.filter(service => {
        return checkType === 'sell'
            ? service.service !== upgradeService_legacy && service.service !== upgradeService
            : service.name !== upgradeService_legacy && service.name !== upgradeService
    })

    const paidByCashOrCard = []
    const paidByBank = []

    for (const service of notUpgrades) {
        if (service.payment_type === paymentTypes.bank) {
            paidByBank.push(service)
            continue
        }

        if (!isPrinted[service.id]) {
            continue
        }

        paidByCashOrCard.push(service)
    }

    return {
        paidByCashOrCard,
        paidByBank,
    }
}

export const saveServiceTransaction = async ({
                                                 checkType,
                                                 items,
                                                 printedItems,
                                                 reduxStates,
                                                 printCashierInfo: {Info: checkInfo} = {}
                                             }) => {
    try {
        LogsAPI.event('Начато сохранение service_transaction')

        const {paidByCashOrCard, paidByBank} = getTransactionServices({
            checkType,
            services: items.services,
            printedServices: printedItems.services,
        })

        if (paidByCashOrCard.length === 0 && paidByBank.length === 0) {
            LogsAPI.event(
                'Прервано сохранение service_transaction - не найдено подходящих услуг'
            )
            return {success: true}
        }

        const {reservation, session} = reduxStates.current

        if (!reservation.pk) {
            throw new Error('отсутствует pk брони')
        }

        const {booking_number, specialServices} = reservation
        const {adminID, lcode} = session

        const isRefund = checkType === 'refund'

        const saveTransaction = async ({
                                           services,
                                           isAdminBonusesApplied = true,
                                       }) => {
            const getTransactionItems = transactionItemsMappers[checkType]

            const requestPayload = {
                booking_number,
                booking_number_uuid: reservation.pk,
                lcode,
                admin: adminID,
                isRefund,
                items: getTransactionItems(services, specialServices),
                is_admin_bonuses_applied: isAdminBonusesApplied,
                kkt: checkInfo?.KktNumber
            }

            LogsAPI.custom(
                `Данные для запроса на /service_transaction: ${JSON.stringify(
                    requestPayload
                )}`
            )

            const savedTransaction = await createServiceTransaction(requestPayload)

            LogsAPI.event(
                `Сохранена service_transaction: ${JSON.stringify(savedTransaction)}`
            )
        }

        const promises = []

        if (paidByCashOrCard.length > 0) {
            promises.push(
                saveTransaction({
                    services: paidByCashOrCard,
                })
            )
        }

        if (paidByBank.length > 0) {
            promises.push(
                saveTransaction({
                    services: paidByBank,
                    isAdminBonusesApplied: false,
                })
            )
        }

        await Promise.all(promises)

        return {success: true}
    } catch (error) {
        const errorMessage = error?.message ?? 'Неизвестная ошибка'
        const message = `Не удалось сохранить service_transaction: ${errorMessage}`

        LogsAPI.error(message)

        sendBugReport({
            message: message + '(сообщение сгенерировано автоматически)',
        })

        return {success: false, error: errorMessage}
    }
}
