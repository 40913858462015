import store from '../../../../store'
import { kkmAPI } from '../../../api/kkm-api'
import { checkKKMShift } from '../check-kkm-shift'
import { callModal, modalTemplates } from '../../../../Components/dynamic-modal'
import moment from 'moment'
import { createCallbacksHelpers } from '../create-callbacks-helpers'

export const custom = ({ getPrintDataFn }) => async ({
  printScopes = { days: true, services: true },
  useProcessing = true,
  customData = {},
  beforePrint = () => {},
  afterPrint = () => {},
  onSuccess = () => {},
  onFailure = () => {},
  onPrintFinish = () => {},
  onCancel = () => {},
  onFinish = () => {},
  whenCheckPrinterIsDisabled = () => {},
  onShiftCheckFailure = ({ shiftCheckError }) => {
    callModal(
      modalTemplates.alert({
        title: 'Ошибка',
        text: `Не удалось проверить смену KKM: \n${shiftCheckError}`,
      })
    )
  },
}) => {
  const { addCallbacksData, call, finish } = createCallbacksHelpers({
    onFinish,
  })

  const state = store.getState()

  const isPrinterOn = state.settings.printerIsOn

  const printData = await getPrintDataFn({
    state,
    printScopes,
    customData,
    isPrinterOn,
    useProcessing,
  })

  addCallbacksData(printData)
  addCallbacksData({
    wasPrinted: false,
    printResult: null,
  })

  if (!isPrinterOn) {
    return finish([whenCheckPrinterIsDisabled])
  }
  let printCashierInfo = {}
  try {
    printCashierInfo = await checkKKMShift(state)
  } catch (err) {
    addCallbacksData({
      shiftCheckError: err.message,
    })

    return finish([onShiftCheckFailure])
  }

  if (printData.wasCanceled) {
    return finish([onCancel])
  }

  let wasPrinted = !printData.shouldPrint
  let printResult = null
  let printDateObj = moment()

  await call(beforePrint)

  if (printData.shouldPrint) {
    const isCash = printData.checkTotals.cash !== 0
    const isRefund = printData.checkType === 'refund'
    printResult = await kkmAPI.print(printData.printObject)
    printDateObj = moment()
    wasPrinted =
      !printResult.Error && (printResult.ElectronicPayment || (isRefund || isCash || 'RezultProcessing' in printResult))
    if (!wasPrinted && !printResult.Error) {
      printResult.Error = 'Что-то пошло не так'
    }
  }

  addCallbacksData({
    wasPrinted,
    printResult,
    printDateObj,
    printCashierInfo
  })

  await call(afterPrint)

  if (wasPrinted) {
    await call(onSuccess)
  } else {
    await call(onFailure)
  }

  return finish([onPrintFinish])
}
