export const LOAD_DATA_REQUEST = 'ghotel/grid/LOAD_DATA_REQUEST'
export const LOAD_DATA_SUCCESS = 'ghotel/grid/LOAD_DATA_SUCCESS'
export const LOAD_DATA_FAILURE = 'ghotel/grid/LOAD_DATA_FAILURE'

export const LOAD_NOTES_SUCCESS = 'ghotel/grid/LOAD_NOTES_SUCCESS'
export const LOAD_ROOM_OCCUPANCY = 'ghotel/grid/LOAD_ROOM_OCCUPANCY'

export const LOAD_GROUP_RESERVATIONS_SUCCESS =
  'ghotel/grid/LOAD_GROUP_RESERVATIONS_SUCCESS'

export const UPDATE_RESERVATION = 'ghotel/grid/UPDATE_RESERVATION'

export const SET_SIZE = 'ghotel/grid/SET_SIZE'

export const RESET = 'ghotel/grid/RESET'
