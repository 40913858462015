import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Services} from './Services'
import {reservationSelectors} from '../../../Modules/reservation'

import {
    copyService,
    createService,
    deleteService,
    refundService,
    updateServiceDate,
    updateServicePaymentType,
    updateServiceQuantity,
} from '../../../Modules/reservation/actions/services'

const mSTP = (state, ownProps) => ({
    items: reservationSelectors.serviceServices(state),
    availableDates: reservationSelectors.availableDates(state),
    availableServices: reservationSelectors.availableServices(state),
    validationErrors: reservationSelectors.servicesValidationErrors(state),
    ...ownProps,
})

const mDTP = dispatch => ({
    actions: {
        create: ({
                     name,
                     price,
                     quantity,
                     paymentType,
                     date,
                     special_service_id,
                     nds_value
                 }) =>
            dispatch(
                createService({
                    serviceType: 'service',
                    name,
                    price,
                    quantity,
                    paymentType,
                    date,
                    special_service_id,
                    nds_value
                })
            ),

        setDate: bindActionCreators(updateServiceDate, dispatch),
        setQuantity: bindActionCreators(updateServiceQuantity, dispatch),
        setPaymentType: bindActionCreators(updateServicePaymentType, dispatch),

        copy: bindActionCreators(copyService, dispatch),
        delete: bindActionCreators(deleteService, dispatch),
        refund: bindActionCreators(refundService, dispatch),
    },
})

export const ConnectedServices = connect(mSTP, mDTP)(Services)
