import moment from "moment";

export const SIZE_LS_KEY = 'grid/size'


export const groupBy = (xs) => {
    return xs.reduce((rv, x) => {
        if (!rv[x.name])
            rv[x.name] = x.room_id
        return rv;
    }, {});
};

export const isRoomOccupancyLow = num => num < 0 && num >= -3
export const isRoomOccupancyExtremelyLow = num => num <= -4


const range1 = (startDate, diff) => {
    let range = []
    for (let i = 0; i <= diff; i++) {
        range.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'))
    }
    return range
}

export const getRange = (startDate, endDate) => {
    let fromDate = moment(startDate)
    let toDate = moment(endDate)
    let diff = toDate.diff(fromDate, 'days')
    return range1(startDate, diff)
}

export const getDateRange = ({gridDate, daysInFuture, daysInPast}) => {
    const currentDate = gridDate ? gridDate : moment().format('YYYY-MM-DD')
    const date = moment(currentDate).subtract(daysInPast, 'days')
    const result = range1(date, daysInPast + 1 + daysInFuture)

    return {
        start: result[0],
        end: result[result.length - 1]
    }
}
