import moment from 'moment'
import {SelectionState} from './selection-state'
import history from '../../../../../Modules/helpers/history'
import Cookies from 'js-cookie'
import * as types from '../../../../../Modules/session/types'
import {ccAPI} from '../../../../../Modules/api/ccAPI'
import {modalActions} from '../../../../dynamic-modal/templates/confirm'
import {buildAvailabilityErrorModal} from '../../../../../Modules/helpers/check-category-availability'
import {statusWarningAlert} from '../room-statuses/room-status-alert'

function findCellNode(node) {
    if (node == null) return null

    const isCell = node.classList.contains('tbody-cell')

    if (isCell) return node

    return findCellNode(node.parentElement)
}

function getCanBeSelected(cellNode) {
    const {date} = cellNode.dataset

    const yesterday = moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')

    return moment(date).isSameOrAfter(yesterday)
}

export function handleMouseDown({event}) {
    const cellNode = findCellNode(event.target)

    const isMouseOnCell = Boolean(cellNode)

    if (!isMouseOnCell) {
        return
    }

    const isCellEmpty = cellNode.dataset.isEmpty === 'true'

    if (!isCellEmpty) {
        return
    }

    const canBeSelected = getCanBeSelected(cellNode)

    if (!canBeSelected) {
        return
    }

    const {room, date} = cellNode.dataset

    SelectionState.updateState({
        isActive: true,
        selectedRoom: room,
        start: date,
        end: date,
    })
}

export async function handleMouseUp({mapRoomIdToRoom, isRoomStatusesEnabled}) {
    const currentState = SelectionState.getState()

    const {isActive} = currentState

    if (!isActive) {
        return
    }
    const {selectedRoom} = currentState
    const {name: roomName, status, busy_with_employee_dates, repair_schedule} = mapRoomIdToRoom[selectedRoom]

    let {start, end} = currentState
    if (moment(start).isAfter(end)) [start, end] = [end, start]

    // reservation.end should be the last reserved_day.date + 1 day
    const endPlusOne = moment(end)
        .add(1, 'day')
        .format('YYYY-MM-DD')

    const date = moment(start)
    const data = {
        lcode: {lcode: Cookies.getJSON(types.LCODE).lcode},
        reserved_days: []
    }
    while (date.format('YYYY-MM-DD') !== endPlusOne) {
        data.reserved_days.push({
            date: date.format('YYYY-MM-DD'),
            room: {
                room_id: selectedRoom
            }
        })
        date.add(1, 'day')
    }
    let goAhead = true

    if (isRoomStatusesEnabled) {
        goAhead = await statusWarningAlert({status, busy_with_employee_dates, repair_schedule}, start, end)
    }

    if (goAhead) {
        await ccAPI.checkRomAvailability(data)
            .then(async _ => {
                history.push(`/new-reservation?room=${selectedRoom}&roomType=${roomName}&start=${start}&end=${endPlusOne}`)
            }).catch(async ({code, meta, details_pretty_ru}) => {
                    if (+code === 90 || +code === 80) {
                        const res = meta.reduce((a, b) => {
                            const [key, value] = Object.entries(b)[0]
                            if (value <= 0)
                                a.push({[key]: value})
                            return a
                        }, [])
                        if (res.length) {
                            await buildAvailabilityErrorModal({meta, details_pretty_ru}).then(res => {
                                if (res === modalActions.confirm) {
                                    history.push(`/new-reservation?room=${selectedRoom}&roomType=${roomName}&start=${start}&end=${endPlusOne}`)
                                }
                            })
                        } else
                            history.push(`/new-reservation?room=${selectedRoom}&roomType=${roomName}&start=${start}&end=${endPlusOne}`)
                    }
                }
            )
    }

    SelectionState.reset()
}

export function updateSelectionState({event}) {
    const currentState = SelectionState.getState()

    const {isActive} = currentState

    if (!isActive) {
        return
    }

    const cellNode = findCellNode(event.target)

    const isMouseOnCell = Boolean(cellNode)

    if (!isMouseOnCell) {
        SelectionState.reset()
        return
    }

    const isCellEmpty = cellNode.dataset.isEmpty === 'true'

    if (!isCellEmpty) {
        SelectionState.reset()
        return
    }

    const {selectedRoom, start, end} = currentState

    const {room, date} = cellNode.dataset

    if (room !== selectedRoom) {
        SelectionState.reset()
        return
    }

    if (date === end) {
        return
    }

    const yesterday = moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
    const canBeSelected = moment(date).isSameOrAfter(yesterday)

    if (!canBeSelected) {
        return
    }

    SelectionState.updateState({
        isActive: true,
        selectedRoom,
        start,
        end: date,
    })
}

export function resetSelection() {
    SelectionState.reset()
}
