import moment from 'moment'
import numberToWords from './numberToWords'
import {capitalizeFirstLetter} from './capitalizeFirstLetter'
import {
    getDiscountedItemTotal,
    getPrintedDiscountedItemPrice,
    getPrintedItemTotal,
} from '../../../Modules/helpers/item-selectors'
import {upgradeService, upgradeService_legacy} from "../../../Modules/helpers/servicesNames";

const getProcessedBreakfasts = breakfasts =>
    breakfasts.reduce((a, b) => {
        a.quantity += b.qty
        a.totalPrice += b.price * b.qty
        a.price = a.totalPrice / a.quantity
        return a
    }, {name: 'Завтраки', quantity: 0, price: 0, totalPrice: 0})


const getProcessedServices = services => {
    const result = services
        .filter(service => service.service !== upgradeService_legacy && service.service !== upgradeService)
        .map(service => {
            const price = getPrintedDiscountedItemPrice(service)
            const quantity = Number(service.quantity)
            const totalPrice = getDiscountedItemTotal(service)

            return {
                name: service.service,
                quantity,
                price,
                totalPrice,
            }
        })

    const groupedUpgrades = services
        .filter(service => service.service === upgradeService && service.service === upgradeService_legacy)
        .reduce((acc, service) => {
            const price = getPrintedDiscountedItemPrice(service)

            const current = acc[price] || 0

            acc[price] = current + 1

            return acc
        }, {})

    for (let key in groupedUpgrades) {
        const price = Number(key)
        const quantity = groupedUpgrades[key]
        const totalPrice = price * quantity

        result.push({
            name: upgradeService,
            quantity,
            price,
            totalPrice,
        })
    }

    return result
}

export function getValuesFromData({reservation, calculatedItems, breakfasts = []}) {
    let guestName = ''
    let roomID = ''
    let formattedCheckIn = ''
    let formattedCheckOut = ''
    let daysCount = ''
    let dayAveragePrice = ''
    let daysTotalPrice = ''
    let processedServices = []
    let servicesTotalPrice = ''
    let processedBreakfasts = []
    let breakfastTotalPrice = ''
    let totalPrice = ''
    let totalPriceInt = ''
    let totalPriceIntString = ''
    let totalPriceFract = ''
    let paymentDate = ''

    if (reservation) {
        const {days, services} = calculatedItems

        guestName = reservation.guest_name
        roomID = days.length && days[0].room && days[0].room.room_id

        formattedCheckIn = moment(
            `${reservation.start} ${reservation.check_in_time}`,
            'YYYY-MM-DD HH:mm:ss'
        ).format('DD.MM.YYYYг; HH:mm')

        formattedCheckOut = moment(
            `${reservation.end} ${reservation.check_out_time}`,
            'YYYY-MM-DD HH:mm:ss'
        ).format('DD.MM.YYYYг; HH:mm')

        daysCount = days.filter(d => d.payment_date !== '').length

        daysTotalPrice = days.reduce((acc, day) => {
            if (!day.payment_date) {
                return acc
            }
            const totalPrice = getPrintedItemTotal(day)

            return acc + totalPrice
        }, 0)

        dayAveragePrice = daysTotalPrice / daysCount

        processedServices = getProcessedServices(services)

        servicesTotalPrice = processedServices.reduce((acc, service) => {
            return acc + service.totalPrice
        }, 0)

        processedBreakfasts = getProcessedBreakfasts(breakfasts)
        breakfastTotalPrice = breakfasts.reduce((a, b) => a + b.price * b.qty, 0)

        totalPrice = daysTotalPrice + servicesTotalPrice + breakfastTotalPrice

        totalPriceInt = Math.floor(totalPrice)

        totalPriceIntString = capitalizeFirstLetter(
            numberToWords(Math.floor(totalPrice))
        )

        totalPriceFract = (totalPrice % 1).toFixed(2).slice(2)

        paymentDate = moment().format('DD.MM.YYYYг')
    }

    return {
        guestName,
        roomID,
        formattedCheckIn,
        formattedCheckOut,
        daysCount,
        dayAveragePrice,
        daysTotalPrice,
        processedServices,
        servicesTotalPrice,
        processedBreakfasts,
        breakfastTotalPrice,
        totalPrice,
        totalPriceInt,
        totalPriceIntString,
        totalPriceFract,
        paymentDate,
    }
}
