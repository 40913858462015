import React from 'react'
import {Formik} from "formik";
import {Form} from "react-bootstrap";
import {SingleDatePicker} from "../common/DatePicker";
import TextField from "../common/Textfield";
import {useSelector} from "react-redux";
import {sessionSelectors} from "../../Modules/session";
import {useActions} from "../common/hooks/use-actions";
import {depositsActions} from "../../Modules/deposits";
import {Button} from "../buttons";
import styled from 'styled-components'

const Block = styled.div`
    width: fit-content;
    margin: auto;
    padding-bottom: 25px;
`


export const SearchForm = () => {
    const lcode = useSelector(sessionSelectors.lcode)
    const {load} = useActions(depositsActions)

    return (
        <Block>
            <Formik
                initialValues={{
                    deposit_created_date: null,
                    deposit_paid_date: null,
                    booking_number: ''
                }}
                onSubmit={async (values, actions) => {
                    await load({lcode, ...values})
                    actions.setSubmitting(false)
                }}
                render={({
                             resetForm,
                             handleSubmit,
                             setFieldValue,
                             values
                         }) => (
                    <Form onSubmit={handleSubmit}>
                        <SingleDatePicker
                            label="По дате создания депозита"
                            value={values.deposit_created_date}
                            onChange={val => setFieldValue('deposit_created_date', val)}
                        />

                        <SingleDatePicker
                            label="По дате списания"
                            value={values.deposit_paid_date}
                            onChange={val => setFieldValue('deposit_paid_date', val)}
                        />

                        <TextField
                            name="По номеру брони"
                            value={values.booking_number}
                            style={{marginBottom: 15, width: 240}}
                            onChange={val => setFieldValue('booking_number', val)}
                        />

                        <Button onClick={handleSubmit}>
                            Искать
                        </Button>
                        <Button type="danger" onClick={() => {
                            resetForm()
                            handleSubmit()
                        }}>
                            Очистить
                        </Button>
                    </Form>

                )}
            />
        </Block>
    )
}
