import moment from "moment/moment";
import React, {Fragment} from "react";
import {Table} from "react-bootstrap";
import styled from 'styled-components'
import {rub} from "../../Modules/formatters/currency";
import {Button} from "../buttons";
import {kkmAPI} from "../../Modules/api/kkm-api";
import generateGUID from "../../Modules/CheckPrintController/generateGUID";
import {Notifications} from "../../Modules/Notifications";
import {callPrompt, modalTemplates} from "../dynamic-modal";
import {useActions} from "../common/hooks/use-actions";
import {depositsActions} from "../../Modules/deposits";

const Row = styled.tr`
    cursor: pointer;
`

const Cell = styled.td`
    vertical-align: middle;
    padding: 16px 8px;
    width: ${props => props.width}px;
    text-align: ${props => props.textAlign};
`

export const DepositTable = ({items}) => {

    return (
        <Fragment>
            <Table
                responsive
                className="table-hover condensed"
                style={{width: '100%'}}
            >
                <thead>
                <HeaderRow/>
                </thead>
                <tbody>{items?.map((t, index) =>
                    <TransactionRow
                        data={t}
                        key={t.universal_id_for_deposit + index}
                    />
                )}
                </tbody>
            </Table>
        </Fragment>
    )
}

const HeaderRow = () => (
    <tr>
        <th>Номер бронирования</th>
        <th>Дата удержания</th>
        <th>Сумма депозита</th>
        <th>Остаток</th>
        <th>Списания</th>
    </tr>
)

const TransactionRow = ({
                            data,
                        }) => {

    const {update} = useActions(depositsActions)
    const {
        universal_id_for_deposit,
        held_deposit_amount,
        booking_number,
        withdrawals,
        is_active,
        created_at
    } = data

    const balance = held_deposit_amount - withdrawals.reduce((a, {amount}) => a + amount, 0)


    const refund = () => {
        kkmAPI.refundDeposit({
            Amount: balance,
            UniversalID: universal_id_for_deposit,
            IdCommand: generateGUID()
        }).then(async ({Error, Amount}) => {
            if (!Error) {
                const options = {
                    ...data,
                    is_active: false,
                    returned_deposit_amount: Amount
                }
                await update(options).then(_ => {
                    Notifications.success('Успех')
                }).catch(_ => Notifications.failure('Что-то пошло не так'))
            } else Notifications.failure('Что-то пошло не так')
        })
    }

    const release = () => {
        callPrompt({
            title: 'Депозит',
            text: [
                'Введите сумму депозита',
            ],
            initialValue: balance,
            validators: [
                ...modalTemplates.prompt.defaultValidators,
                {
                    fn: value => !Number.isNaN(Number(value)),
                    message: 'Значение должно быть числом',
                },
                {
                    fn: value => Number(value) > 0,
                    message: 'Значение должно быть больше 0',
                },
                {
                    fn: value => Number(value) <= balance,
                    message: `Значение должно быть больше ${balance}`,
                }]
        }).then(async value => {
            if (value) {
                await kkmAPI.releaseDeposit({
                    Amount: value,
                    UniversalID: universal_id_for_deposit,
                    IdCommand: generateGUID()
                }).then(async ({Error}) => {
                        if (!Error) {
                            const options = {
                                ...data,
                                withdrawals: [...withdrawals, {date: moment().format('YYYY-MM-DD'), amount: value}]
                            }
                            await update(options).then(_ => {
                                Notifications.success('Успех')
                            }).catch(_ => Notifications.failure('Что-то пошло не так'))
                        } else Notifications.failure('Что-то пошло не так')
                    }
                )
            }
        })
    }

    return (
        <Row onClick={() => {
        }}>
            <Cell>{booking_number}</Cell>
            <Cell>{moment(created_at).format('DD.MM.YYYY')}</Cell>
            <Cell>{rub(held_deposit_amount)}</Cell>
            <Cell>{rub(balance)}</Cell>
            <Cell width={200}>{withdrawals.map(({date, amount}, index) => <div key={date + amount + index}>
                <strong>{moment(date).format('DD.MM.YYYY')}</strong> - {rub(amount)}
            </div>)}</Cell>
            <Cell>
                <Button onClick={release} disabled={!is_active}>Списать</Button>
                <Button type="warning" onClick={refund} disabled={!is_active}>Вернуть</Button>
            </Cell>
        </Row>
    )
}
